import React, {useState, useRef} from "react"
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import Airform from 'react-airform';
import {SearchBar} from "../components/search";
import logo from "../images/gizapedia_logo.jpg";
import logo_inb from "../images/gizapedia_logo_inverse.png";
import bg from "../images/so-white.png";
import oin from "../images/lauburu.png";
import collab from "../images/esku1.png";
import harpidetu from "../images/harp1.png";
import clsx from "clsx";

const Layout = ({ title, children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }
  const scrolled = useRef(null)

  return (
    <div className="dena" style={{
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'repeat'
    }}>
    <div className="container mx-auto" style={{
      background: "#fff",
      padding: '0 2em',
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #eee',
      borderBottom: '1px solid #eee',
      paddingBottom: '3em'
    }}>
      <header className="global-header">
      <div className="main-heading">
        <Link to="/">
          <img src={logo} className="logo" />
          <span>Gizapedia</span>
          </Link>
      </div>
      <nav>
        <div className="nabigazioa">
        <div onClick={toggleMenu} className="burger">{menuOpen ? '⨯' : '☰'}</div>
        
          <div className={clsx('menu', menuOpen && 'menuOpen')}>
          <Link to="/">Hasiera</Link>
          <Link to="/arakatu">Arakatu</Link>
          <Link to="/azken-artikuluak">Azken artikuluak</Link>
          <Link to="https://hiztegia.gizapedia.org">Hiztegia</Link>
          <Link to="/nor-garen">Nor garen</Link>
          </div>
        
        </div>

        <SearchBar />
      </nav>
      </header>
      <main className="edukia">{children}</main>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4" >
        <div className="artikulua-eskatu">
          <img src={collab} className="w-4/5 md:w-2/5 p-2 md:p-1 mx-auto rounded-full" />
            <h3>Artikulu bat eskatu</h3>
            <p>Erabili ezazu galdetegi hau artikulu eskaera bat bidaltzeko. Lehenbailehen osatzen saiatuko gara.</p>
            <Airform style={{marginBottom: '3rem'}} email="info@gizapedia.org">
              <input type="text" name="email" className="px-4 py-2 my-2  border border-purple-400 md:w-2/5 w-full text-center" placeholder="Email helbidea" /><br />
              <input type="text" name="message" className="px-4 py-2 my-2  border border-purple-400 md:w-2/5 w-full text-center" placeholder="Artikulu eskaera" />
              <br />
              <button className="btn-hero px-5 py-2 bg-purple-400 font-bold text-white lowercase rounded-full ">Eskaera egin</button>
            </Airform>
        </div>
        <div className="harpidetu">
          <img src={harpidetu} className="w-4/5 md:w-2/5 p-2 md:p-1 mx-auto rounded-full" />
            <h3>Harpidetu zaitez</h3>
            <p>Gure azken edukien berri jaso nahi baduzu zure email helbidean, egin zaitez harpidedun hurrengo galdetegi hontan.</p>
            <Airform style={{marginBottom: '3rem'}} email="info@gizapedia.org">
              <input type="text" name="email" className="px-4 py-2 my-2  border border-purple-400 md:w-2/5 w-full text-center" placeholder="Email helbidea" /><br />
              <br />
              <button className="btn-hero px-5 py-2 bg-purple-400 font-bold text-white lowercase rounded-full ">Harpidetu</button>
            </Airform>
        
      </div>
      </div>
      
      <footer style={{background: '#ebe7df', backgroundImage: `url(${oin})`, border: '3px solid #eee', padding: '7em 1em'}}>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
        <div className="navfooter p-1">
          <ul style={{ textAlign: 'left', marginLeft: '1em' }}>
            <li><Link to="/">Hasiera</Link></li>
            <li><Link to="/arakatu">arakatu</Link></li>
            <li><Link to='/azken-artikuluak'>Azken artikuluak</Link></li>
            <li><Link to='https://biltegia.gizapedia.org'>Hiztegia</Link></li>
            <li><Link to='/nor-garen'>Nor garen</Link></li>
            
            <li className="text-sm mt-2">Web honetako egileek edukien eskubide guztiak atxikitzen dituzte. <br />  Copyright {new Date().getFullYear()},
        {` `}
        <a href="https://hirusta.io" className="text-sm">Hirustaren eskutik</a></li>
          </ul>
        
        </div>
        <div className="p-1">

        <ul style={{ textAlign: 'left', marginLeft: '1em' }}>
        <a rel="me" href="https://mastodon.eus/@nko" style={{display: 'none'}}>Mastodon</a>
       </ul>
        </div>
        <div className="p-0">
        </div>
        <div className="p-1">
          <img className="md:my-10 mx-auto" src={logo_inb} width={200} />
        </div>
      </div>
      </footer>
      <CookieConsent debug={false} style={{ background: "#eee", color: "#333" }}
  buttonStyle={{ backgroundColor: "rgba(167, 139, 250, var(--tw-border-opacity))", fontWeight: "bold", color: "#eee", fontSize: "13px" }} buttonText="OK"
    >Web orrialde honek cookieak erabilzen diu zure esperientzia hobetzeko &nbsp; &nbsp; <Link to="/privacy" style={{ color: "rgba(167, 139, 250, var(--tw-border-opacity))" }}>Ikusi gure pribazitate politika</Link></CookieConsent>
      &nbsp;
    </div>
    </div>
  )
}

export default Layout
