import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import useConstant from 'use-constant';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import _ from 'lodash';
import bilatu from "../images/bilatu.svg";


export const SearchBar = ({data}) => {
  const [inputText, setInputText] = useState('');
  const mixpanel = useMixpanel();
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      navigate("/bilatu#?query=" + inputText, { replace: true })
    }
  };
  // const { inputText, setInputText, searchResults } = useSearchTerms();
  return (
    <>
    <div className="bilaketa">
      <div style={{
        zIndex: 1500
      }} className="menuBilaketa">
      <span className="bilatu" style={{
        color: '#444'
      }}>Bilatu</span>
      <input
        className="bilainput p-1 border border-purple-400"
        type="text"
        value={inputText || ''}
        onChange={e => setInputText(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Link style={{
        marginTop: '0.4em',

        marginLeft: '0.75em'
      }} className="bilatu-b" to={'/bilatu#?query=' + inputText} replace={true}><img src={bilatu} /></Link>
      </div>
      
     </div>
    </>
  )
}